.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

iframe#email-preview {
  border-style: none;
}

/* page subtitle */
.Polaris-Text--headingMd {
  text-shadow: #fff 1px 0 1px;
}

.Polaris-Frame {
  background: url("/public/background.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.Polaris-Frame__Content {
  zoom: 1.1;
}

video {
  margin-bottom: -7px;
}

img.logo {
  filter: grayscale(1);
  opacity: 0.7;
  height: 25px;
}

img.logo.shopify-plus {
  opacity: 0.5;
}

img.logo.klaviyo {
  opacity: 0.6;
}

img.logo.recharge {
  opacity: 0.6;
}

img.logo.xero {
  opacity: 0.9;
}
